import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FilledButton, OutlineButton } from '../../../utils/component';
import Modal from '@mui/material/Modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { CircularProgress, Typography } from '@mui/material';
import { getData, postData } from '../../../services/APIService';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../redux/features/snackbar/snackbarSlice';

const Modalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '25vw',
  bgcolor: 'background.paper',
  boxShadow: 40,
  p: 5,
};

const roleList = ['BOS Admin', 'Sales Admin', 'Product Admin', 'KAM', 'Invoice', 'User'];
const menuSubmenuList = [
  { name: 'User Management', subMenu: [{ name: 'BOS Employee' }, { name: 'Customer' }, { name: 'Supplier' }, { name: 'Vendor' }] },
  { name: 'Enquiry Management', subMenu: [{ name: 'Add/Edit Enquiry' }] },
  {
    name: 'Product Management',
    subMenu: [{ name: 'Add/Edit Product' }],
  },
  { name: 'Order Management', subMenu: [{ name: 'Add/Edit Order' }] },
  { name: 'Community', subMenu: [{ name: 'Add/Edit Community' }] },
  // { name: 'Permission Management', subMenu: [] },
  { name: 'Master', subMenu: [{ name: 'BOS Master' }, { name: 'Dropdown List Master' }, { name: 'Tally Master' }] },
];
function Row({ row, menuPermissionData, handleMenuChange, handleSubMenuChange }) {
  const [open, setOpen] = useState(false);
  const menuPermissions = menuPermissionData.find((item) => item.module_name === row.name);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell style={{ width: '48px' }}>
          {row.subMenu && row.subMenu.length > 0 && (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row" style={{ width: '200px', fontFamily: 'poppins-regular' }}>
          {row.name}
        </TableCell>
        {roleList.map((role) => {
          const permission = menuPermissions?.permissions.find((perm) => perm.role_name.toLowerCase() === role.toLowerCase());
          return (
            <TableCell key={role} align="center" style={{ width: '120px', padding: 0 }}>
              <Checkbox
                checked={permission?.is_checked || false}
                onChange={() => handleMenuChange(permission, row.name)}
                sx={{ '&.Mui-checked': { color: '#63A47C' } }}
                disabled={role === 'BOS Admin'}
              />
            </TableCell>
          );
        })}
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="submenu">
                <TableBody>
                  {row.subMenu.map((submenu) => {
                    const subModule = menuPermissionData.find((module) => module.module_name.toLowerCase() === submenu.name.toLowerCase());
                    return (
                      <TableRow key={submenu.name}>
                        <TableCell style={{ width: '48px' }} />
                        <TableCell style={{ width: '200px', fontFamily: 'poppins-regular' }}>{submenu.name}</TableCell>
                        {roleList.map((role) => {
                          const permission = subModule?.permissions.find((perm) => perm.role_name.toLowerCase() === role.toLowerCase());

                          return (
                            <TableCell key={role} align="center" style={{ width: '150px', padding: 0 }}>
                              <Checkbox
                                checked={permission?.is_checked || false}
                                onChange={() => handleSubMenuChange(permission, submenu.name, row.name)}
                                sx={{ '&.Mui-checked': { color: '#63A47C' } }}
                                disabled={permission?.role_name === 'BOS Admin'}
                              />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    subMenu: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.object)]).isRequired,
  }).isRequired,
  menuPermissionData: PropTypes.arrayOf(
    PropTypes.shape({
      module_name: PropTypes.string.isRequired,
      permissions: PropTypes.arrayOf(
        PropTypes.shape({
          role_name: PropTypes.string.isRequired,
          role_id: PropTypes.string.isRequired,
          is_checked: PropTypes.bool.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  handleMenuChange: PropTypes.func.isRequired,
  handleSubMenuChange: PropTypes.func.isRequired,
};

export default function MenuPermission() {
  const [open, setOpen] = useState(false);
  const [menuPermissionData, setMenuPermissionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await getData('/mst_module_access', {}, userData.token);
      if (res.data.status && res.data.data) {
        setMenuPermissionData(res.data.data);
      } else {
        dispatch(showSnackbar({ message: res.response.data.message, severity: 'error' }));
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
    }
  };

  const handleMenuChange = (permission, moduleName) => {
    const menuSubmenuObj = menuSubmenuList.find((menuPermission) => menuPermission.name === moduleName);
    if (!menuSubmenuObj) return;

    const submenuPermission = menuPermissionData.find((menuPermission) => menuPermission.module_name === moduleName);
    if (!submenuPermission) return;

    const rolePermission = submenuPermission.permissions.find((rolePermission) => rolePermission.role_id === permission.role_id);
    const permissionStatus = !permission.is_checked;
    if (rolePermission) {
      rolePermission.is_checked = permissionStatus;
    }

    menuSubmenuObj.subMenu.map((submenu) => {
      const submenuPermission = menuPermissionData.find((menuPermission) => menuPermission.module_name === submenu.name);
      if (submenuPermission) {
        const rolePermission = submenuPermission.permissions.find((rolePermission) => rolePermission.role_id === permission.role_id);
        if (rolePermission) {
          rolePermission.is_checked = permissionStatus;
        }
      }
    });

    setMenuPermissionData((prevData) => [...prevData]);
  };

  const handleSubMenuChange = async (permission, subMenu, mainMenu) => {
    const submenuPermission = menuPermissionData.find((menuPermission) => menuPermission.module_name === subMenu);
    if (!submenuPermission) return;

    const rolePermission = submenuPermission.permissions.find((rolePermission) => rolePermission.role_id === permission.role_id);
    const permissionStatus = !permission.is_checked;
    if (rolePermission) {
      rolePermission.is_checked = permissionStatus;
    }
    const mainMenuPermission = menuPermissionData.find((menuPermission) => menuPermission.module_name === mainMenu);
    const mainMenuRolePermission = mainMenuPermission.permissions.find((rolePermission) => rolePermission.role_id === permission.role_id);
    if (mainMenuRolePermission) {
      const menuPermission = menuSubmenuList.find((menuPermission) => menuPermission.name === mainMenu);
      let isOtherChecked = false;
      menuPermission.subMenu.map((submenu) => {
        const submenuPermission = menuPermissionData.find((menuPermission) => menuPermission.module_name === submenu.name);
        if (submenuPermission) {
          const rolePermission = submenuPermission.permissions.find((rolePermission) => rolePermission.role_id === permission.role_id);
          if (rolePermission && rolePermission.is_checked) {
            isOtherChecked = true;
            return;
          }
        }
      });
      mainMenuRolePermission.is_checked = isOtherChecked ? true : false;
    }
    setMenuPermissionData((prevData) => [...prevData]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await postData('/mst_module_access/add', { permission_data: menuPermissionData }, {}, userData.token);
      dispatch(showSnackbar({ message: `Menu permission updated successfully`, severity: 'success' }));
    } catch (error) {
      dispatch(showSnackbar({ message: error?.response?.data?.message || error.message, severity: 'error' }));
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#0093D3' }}>
              <TableCell style={{ width: '48px' }} />
              <TableCell style={{ width: '200px', color: 'white' }}>Permission</TableCell>
              {roleList.map((role) => (
                <TableCell key={role} align="center" sx={{ color: 'white', width: '120px' }}>
                  {role}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {menuSubmenuList.map((row) => (
              <Row
                key={row.name}
                row={row}
                menuPermissionData={menuPermissionData}
                handleMenuChange={handleMenuChange}
                handleSubMenuChange={handleSubMenuChange}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box width={{ xs: '65vw', sm: '30vw' }} sx={{ margin: '0px auto', marginTop: '30px', display: 'flex', gap: '25px' }}>
        <FilledButton text="Save" onClick={() => setOpen(true)} />
        <OutlineButton text="Cancel" />
      </Box>

      <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={Modalstyle}>
          <CloseRoundedIcon sx={{ float: 'right' }} onClick={() => setOpen(false)} />
          <Typography mt={5} style={{ textAlign: 'center' }}>
            Are you sure you want to make changes to permissions?
          </Typography>
          <Box width="18.8vw" sx={{ margin: 'auto', marginTop: '35px', marginBottom: '10px', display: 'flex', gap: '25px' }}>
            <FilledButton text={loading ? <CircularProgress size={24} /> : 'Confirm'} disabled={loading} onClick={handleSubmit} />
            <OutlineButton text="Cancel" onClick={() => setOpen(false)} />
          </Box>
        </Box>
      </Modal>
    </>
  );
}
