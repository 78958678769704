import {
  Box,
  Button,
  CircularProgress,
  Modal,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import { getData, postData, updateData } from '../../../../services/APIService';
import { showSnackbar } from '../../../../redux/features/snackbar/snackbarSlice';
import { Heading20Semi } from '../../../../utils/component';
import { Add, Edit } from '../../../../theme/Images.js';
import PropTypes from 'prop-types';
// import BorderColorIcon from '@mui/icons-material/BorderColor';

const headerCellStyle2 = {
  border: '1px solid #0093D3',
  fontFamily: 'poppins-regular',
};
const CustomBtnBox = styled(Box)(() => ({
  height: '35px',
  width: '35px',
  borderRadius: '6px',
  padding: '10px',
  background: '#70FACB4D',
  textAlign: 'center',
  cursor: 'pointer',
  marginBottom: 20,
}));

export function CreditTermMaster({ access }) {
  const initFormData = {
    id: '',
    credit_term: '',
  };
  const [gstList, setGstList] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(initFormData);
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();

  const fetchCreditTermMasterData = async () => {
    await getData(`/mst_credit_terms`, {}, userData.token)
      .then((res) => {
        if (res.data?.data) {
          setGstList(res.data?.data || []);
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch(showSnackbar({ message: error?.response?.data?.message || error.message, severity: 'error' }));
        setGstList([]);
      });
  };

  useEffect(() => {
    fetchCreditTermMasterData();
  }, []);

  const handleOpen = () => {
    setOpen(true);
    setIsEdit(false);
  };
  const handleClose = () => {
    setFormData(initFormData);
    setOpen(false);
    setIsEdit(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const edit = async (item) => {
    setFormData({
      id: item.id,
      credit_term: item.credit_term,
    });
    handleOpen();
    setIsEdit(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (formData.id) await updateData('/mst_credit_terms/' + formData.id, formData, {}, userData.token);
      else await postData('/mst_credit_terms/add', formData, {}, userData.token);
      dispatch(showSnackbar({ message: `Credit term ${formData.id ? 'updated' : 'added'} successfully`, severity: 'success' }));
      fetchCreditTermMasterData();
      handleClose();
    } catch (error) {
      dispatch(showSnackbar({ message: error?.response?.data?.message || error.message, severity: 'error' }));
    } finally {
      setLoading(false);
    }

    setFormData(initFormData);
  };

  return (
    <div>
      <Heading20Semi text="Credit Term Master" />

      {access?.add && (
        <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'flex-end' }}>
          <CustomBtnBox onClick={handleOpen}>
            <img src={Add} alt="add" style={{ height: '22px', width: '22px' }} />
            <Typography sx={{ fontSize: '10px' }}>ADD</Typography>
          </CustomBtnBox>
        </Box>
      )}
      <Box>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" sx={{ minWidth: 650, border: '1px solid #0093D3' }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#0093D3' }}>
                <TableCell sx={{ border: '1px solid #ffffff', fontWeight: 600, color: '#ffffff' }}>Credit Term</TableCell>
                {access?.edit && <TableCell sx={{ border: '1px solid #ffffff', fontWeight: 600, color: '#ffffff' }}>Edit</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {gstList.map((row) => (
                <TableRow key={row.id}>
                  <TableCell sx={headerCellStyle2}>{row.credit_term || ''}</TableCell>
                  {access?.edit && (
                    <TableCell sx={headerCellStyle2}>
                      <img
                        style={{ height: '25px', marginLeft: '10px', marginTop: '5px', cursor: 'pointer' }}
                        src={Edit}
                        alt="edit"
                        onClick={() => edit(row)}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Modal for adding new freight rates */}
      <Modal open={open} onClose={handleClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Paper sx={{ padding: 2, width: 400 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>{isEdit ? 'Edit' : 'Add New'} Credit Term</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Credit Term"
              name="credit_term"
              value={formData.credit_term}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  handleChange(e);
                }
              }}
              fullWidth
              margin="normal"
              // inputProps={{ type: 'number', min: 0 }}
              required
              sx={{
                '& .MuiInputLabel-asterisk': {
                  color: 'red',
                },
              }}
            />
            <Button type="submit" variant="contained" sx={{ mt: 2 }} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
            <Button variant="outlined" sx={{ mt: 2, ml: 2 }} onClick={handleClose}>
              Cancel
            </Button>
          </form>
        </Paper>
      </Modal>
    </div>
  );
}

CreditTermMaster.propTypes = {
  access: PropTypes.object,
};
