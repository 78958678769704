import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getData } from '../../../services/APIService';

export const fetchPermissions = createAsyncThunk('fetchPermissions', async () => {
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const user_id = userData.user.user_id;
  const res = await getData(`users/getPermissionData/${user_id}`, {}, userData.token);
  return res.data.data;
});

const initialState = {
  data: [],
};

export const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    clearPermissions: (state) => {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPermissions.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export const { clearPermissions } = permissionSlice.actions;

export default permissionSlice.reducer;
