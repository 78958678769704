import React, { useState, useEffect } from 'react';
import { Box, Popover, TextField, Typography, styled, InputAdornment, Button, Modal } from '@mui/material';
import Colors from '../../../theme/colors/Color';
import { Heading26Bold } from '../../../common-components';
import PropTypes from 'prop-types';
import { Add, Delete, Filter, Magnifier, Edit } from '../../../theme/Images';
import { useNavigate } from 'react-router-dom';
import { postData } from '../../../services/APIService';
// import TableHeader from '../../../utils/table headings/TableHeaders.json';
// import ListDataTable from '../../../common-components/table/ListDataTable';
import { CustomDataGrid, Heading16Reg } from '../../../utils/component';
import { getStatusBgColor } from '../../../utils/constants/statusColor';
import CommunityFilter from './filters/CommunityFilters';
import Pagination from '../../../utils/component/pagination/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbar } from '../../../redux/features/snackbar/snackbarSlice';
import { getPermision } from '../../../utils/constants/common';

const CustomSearch = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '&.MuiInputBase-root': {
      height: '40px',
      borderRadius: '10px',
      width: '31.8vw',
      background: Colors.white,
    },
  },
}));

const CustomBtnBox = styled(Box)(() => ({
  // border: '1px solid',
  height: '35px',
  width: '35px',
  borderRadius: '6px',
  padding: '8px 6px 5px',
  background: Colors.white,
  textAlign: 'center',
  cursor: 'pointer',
}));

const DeleteModalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20vw',
  bgcolor: 'background.paper',
  boxShadow: 40,
  p: 4,
};

const limit = 10;
let totalPages = 1;
const initialFilters = { realm: [], status: [] };
const CommunityManagement = () => {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem('userDetails'));

  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [filters, setFilters] = useState(initialFilters);
  const [selectedRows, setSelectedRows] = useState([]);

  const [deleteWarning, setDeleteWarning] = useState(false);
  const [allCommunity, setAllCommunity] = useState([]);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const navigate = useNavigate();
  const permis = useSelector((state) => state.permissions.data);
  const access = getPermision(permis, 'Community');

  const handleClickFilter = (event) => {
    setAnchorEl(event.currentTarget);
    setIsFilterActive(true);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
    setIsFilterActive(false);
  };
  useEffect(() => {
    fetchAllCommunity();
  }, [page, filters, keyword]);
  const fetchAllCommunity = async () => {
    try {
      const res = await postData(`/community/getall`, { page, limit, keyword, filters }, {}, userData.token);
      if (res && res.data && res.data.status) {
        totalPages = res.data.data.totalPages;
        setAllCommunity(res.data.data.communities);
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      if (error?.response?.status === 404) setAllCommunity([]);
      console.log(error);
    }
  };
  const handleSearch = (e) => {
    const keyword = e.target.value.trim();
    setKeyword(keyword);
  };
  const handleSelectedFilters = (filter) => {
    setFilters(filter);
    setCurrentPage(1);
  };
  const handleClearFilter = () => {
    setFilters(initialFilters);
    setCurrentPage(1);
  };

  const handleDelete = async () => {
    try {
      const res = await postData('/community/bulkDelete', { communityIds: selectedRows }, {}, userData.token);
      if (res && res && res.data.status) {
        setDeleteWarning(false);
        fetchAllCommunity();
        dispatch(showSnackbar({ message: 'Community deleted successfully', severity: 'success' }));
        // setModalOpen(false);
      } else dispatch(showSnackbar({ message: 'Something went wrong', severity: 'error' }));
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) dispatch(showSnackbar({ message: error.response.data.message, severity: 'error' }));
      else dispatch(showSnackbar({ message: 'Something went wrong...!', severity: 'error' }));
    }
    setSelectedRows([]);
  };
  const open = Boolean(anchorEl);
  const columnsData = [
    {
      field: 'community_no',
      headerName: 'Community No',
      flex: 0.8,
      renderCell: (params) => {
        const { community_id } = params.row;
        const Code = params.value;
        const viewDetails = () => {
          navigate(`/community/edit/${community_id}`, { state: { id: community_id, mode: 'view' } });
        };
        return (
          <Box
            sx={{
              color: Colors.primary,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={viewDetails}
          >
            {Code}
          </Box>
        );
      },
    },
    { field: 'community_name', headerName: 'Name of Community', flex: 2 },
    { field: 'createdAt', headerName: 'Date of Creation', flex: 0.7, valueGetter: (p) => new Date(p).toLocaleDateString() },

    {
      field: 'user_type',
      headerName: 'User Type',
      flex: 0.7,
    },
    {
      field: 'realm',
      headerName: 'Realm',
      flex: 0.7,
    },
    // {
    //   field: 'description',
    //   headerName: 'Description ',
    //   flex: 1,
    // },
    {
      field: 'membersCount',
      headerName: 'No. of Members',
      flex: 0.8,
    },
    {
      field: 'postsCount',
      headerName: 'No. of Posts',
      flex: 0.7,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.8,
      renderCell: (params) => {
        const status = params.value;
        return (
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                background: getStatusBgColor(status),
                height: '25px',
                width: '80px',
                borderRadius: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                padding: '1px 20px',
              }}
            >
              {status}
            </Box>
          </Box>
        );
      },
    },
  ];
  if (access?.edit)
    columnsData.push({
      headerName: 'Edit',
      width: 70,
      renderCell: ({ id }) => {
        const handleEdit = () => {
          navigate(`/community/edit/${id}`, { state: { id, mode: 'edit' } });
        };
        return (
          <img
            style={{ height: '25px', marginLeft: '10px', marginTop: '10px', cursor: 'pointer' }}
            src={Edit}
            alt="edit"
            onClick={handleEdit}
          />
        );
      },
    });

  return (
    <Box>
      <Heading26Bold text={'Community Management'} />
      <Box
        sx={{
          marginTop: '20px',
          height: '10.4vh',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: ' center',
          padding: '0px 30px',
          background: Colors.bg_strip,
        }}
      >
        <CustomSearch
          placeholder="Search Community"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={Magnifier} alt="Search" style={{ width: '20px', height: '20px' }} />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <Box>
            <CustomBtnBox onClick={handleClickFilter}>
              <img src={Filter} alt="filter" style={{ height: '20px', width: '25px' }} />
              <Typography sx={{ fontSize: '10px' }}>FILTER</Typography>
            </CustomBtnBox>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleCloseFilter}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Box>
                <CommunityFilter selectedFilters={handleSelectedFilters} filterData={filters} />
                {isFilterActive && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-end',
                      position: 'relative',
                      bottom: 10,
                      right: 0, // Aligns the button to the right
                      width: '100%', // Makes sure the flex container takes full width
                      paddingRight: '30px', // Adjust spacing from the right if needed
                    }}
                  >
                    <Button variant="contained" size="small" onClick={handleClearFilter}>
                      Clear filter
                    </Button>
                  </Box>
                )}
              </Box>
            </Popover>
          </Box>
          {access?.add && (
            <CustomBtnBox onClick={() => navigate('/community/add')}>
              <img src={Add} alt="add" style={{ height: '22px', width: '22px' }} />
              <Typography sx={{ fontSize: '10px' }}>ADD</Typography>
            </CustomBtnBox>
          )}
          {access?.delete && (
            <CustomBtnBox onClick={() => setDeleteWarning(true)}>
              <img src={Delete} alt="delete" style={{ height: '21px', width: '20px' }} />
              <Typography sx={{ fontSize: '10px' }}>DELETE</Typography>
            </CustomBtnBox>
          )}
        </Box>
      </Box>
      <Box>
        {/* <ListDataTable list={orderListData} path={'/community/edit'} tableHead={TableHeader.Community_List} /> */}
        <CustomDataGrid
          rowIdKey={'community_id'}
          columnsData={columnsData}
          rowsData={allCommunity}
          onSelectCheckbox={(r) => setSelectedRows(r)}
        />
        <Pagination currentPage={page} totalPages={totalPages} onPageChange={(p) => setCurrentPage(p)} />
      </Box>
      <Modal
        open={deleteWarning}
        onClose={() => setDeleteWarning(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={DeleteModalstyle}>
          {selectedRows?.length ? (
            <>
              <Heading16Reg text="Do you want to delete Community permanantly?" />
              <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                <Button variant="outlined" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={() => setDeleteWarning(false)}>
                  Cancel
                </Button>
                <Button variant="contained" color="error" sx={{ borderRadius: '20px', textTransform: 'none' }} onClick={handleDelete}>
                  Delete
                </Button>
              </Box>
            </>
          ) : (
            <Heading16Reg text="Please select Community first." />
          )}
        </Box>
      </Modal>
    </Box>
  );
};
CommunityManagement.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string,
};
export default CommunityManagement;
