import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Modal from '@mui/material/Modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { FilledButton, OutlineButton } from '../../../utils/component';
import { getData, postData } from '../../../services/APIService';
import { showSnackbar } from '../../../redux/features/snackbar/snackbarSlice';
import { useDispatch } from 'react-redux';

const menuSubmenuList = [
  { name: 'User Management', subMenu: [{ name: 'BOS Employee' }, { name: 'Customer' }, { name: 'Supplier' }, { name: 'Vendor' }] },
  { name: 'Enquiry Management', subMenu: [{ name: 'Add/Edit Enquiry' }] },
  {
    name: 'Product Management',
    subMenu: [{ name: 'Add/Edit Product' }],
  },
  { name: 'Order Management', subMenu: [{ name: 'Add/Edit Order' }] },
  { name: 'Community', subMenu: [{ name: 'Add/Edit Community' }] },
  // { name: 'Permission Management', subMenu: [] },
  { name: 'Master', subMenu: [{ name: 'BOS Master' }, { name: 'Dropdown List Master' }, { name: 'Tally Master' }] },
];
const modeList = ['view', 'add', 'edit', 'delete', 'export', 'import'];
function Row(props) {
  const { row, userPermissionData, handleMenuChange, handleSubMenuChange } = props;
  const [open, setOpen] = React.useState(false);
  const accessPermissions = userPermissionData.find((item) => item.module_name === row.name);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell style={{ width: '48px', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
          {row.subMenu && row.subMenu.length > 0 && (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row" style={{ width: '200px', fontFamily: 'poppins-regular' }}>
          {row.name}
        </TableCell>
        {modeList.map((key) => (
          <TableCell key={key} align="center" style={{ width: '120px', padding: 0 }}>
            <Checkbox
              checked={accessPermissions ? accessPermissions[key] : false}
              onChange={() => handleMenuChange(key, row.name)}
              sx={{
                '&.Mui-checked': {
                  color: '#63A47C',
                },
              }}
            />
          </TableCell>
        ))}
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="submenu">
                <TableBody>
                  {row.subMenu.map((submenu) => {
                    const subModulePermission = userPermissionData.find(
                      (module) => module.module_name.toLowerCase() === submenu.name.toLowerCase()
                    );
                    return (
                      <TableRow key={submenu.name}>
                        <TableCell style={{ width: '48px' }} />
                        <TableCell style={{ width: '200px', fontFamily: 'poppins-regular' }}>{submenu.name}</TableCell>
                        {modeList.map((key) => (
                          <TableCell key={key} align="center" style={{ width: '150px', padding: 0 }}>
                            <Checkbox
                              checked={subModulePermission ? subModulePermission[key] : false}
                              onChange={() => handleSubMenuChange(key, submenu.name, row.name)}
                              sx={{
                                '&.Mui-checked': {
                                  color: '#63A47C',
                                },
                              }}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    subMenu: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.object)]).isRequired,
  }).isRequired,
  userPermissionData: PropTypes.arrayOf(
    PropTypes.shape({
      module_name: PropTypes.string.isRequired,
      view: PropTypes.bool.isRequired,
      add: PropTypes.bool.isRequired,
      edit: PropTypes.bool.isRequired,
      delete: PropTypes.bool.isRequired,
      export: PropTypes.bool.isRequired,
      import: PropTypes.bool.isRequired,
    })
  ).isRequired,
  handleMenuChange: PropTypes.func.isRequired,
  handleSubMenuChange: PropTypes.func.isRequired,
};

const Modalstyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '25vw',
  bgcolor: 'background.paper',
  boxShadow: 40,
  p: 5,
};
const UserLevelPermission = () => {
  const [open, setOpen] = useState(false);
  const [allUserList, setAllUserList] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [userPermissionData, setUserPermissionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem('userDetails'));
  const dispatch = useDispatch();

  const fetchUserList = async () => {
    await getData('/users/getAllRoleUsers', {}, userData.token)
      .then((res) => {
        setAllUserList(res.data.data.users);
      })
      .catch((error) => {
        console.error('user roll list', error);
      });
  };
  useEffect(() => {
    const options = allUserList.map((user) => ({
      value: user.user_id,
      label: user.name,
      role: user.role,
    }));
    setUserOptions(options);
    if (options?.length > 0) {
      const user = options[0];
      setSelectedUser(user);
      setSelectedRole({
        value: user.role.role_id,
        label: user.role.role_name,
      });
    }
  }, [allUserList]);
  const fetchData = async () => {
    if (selectedUser && selectedUser?.value) {
      try {
        const res = await getData(`/accessManagement/getPermission?user_id=${selectedUser.value}`, {}, userData.token);
        if (res.data.status && res.data.data) {
          setUserPermissionData([...res.data.data]);
        } else {
          dispatch(showSnackbar({ message: res.response.data.message, severity: 'error' }));
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(showSnackbar({ message: errorMessage, severity: 'error' }));
      }
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);
  useEffect(() => {
    fetchData();
  }, [selectedUser]);

  const handleModalOpen = () => {
    setOpen(true);
  };
  const handleUserChange = (selectedOption) => {
    setSelectedUser(selectedOption);

    if (selectedOption.role) {
      setSelectedRole({
        value: selectedOption.role.role_id,
        label: selectedOption.role.role_name,
      });
    } else {
      setSelectedRole({
        value: null,
        label: 'Role not assigned',
      });
    }
  };
  const handleMenuChange = (key, moduleName) => {
    const menuSubmenuObj = menuSubmenuList.find((menuPermission) => menuPermission.name === moduleName);

    if (!menuSubmenuObj) return;

    const updatedPermissions = userPermissionData.map((item) => {
      if (item.module_name === moduleName) {
        return { ...item, [key]: !item[key] };
      }

      if (menuSubmenuObj.subMenu.some((submenu) => submenu.name === item.module_name)) {
        return { ...item, [key]: !item[key] };
      }

      return item;
    });

    if (!userPermissionData.some((item) => item.module_name === moduleName)) {
      const newModulePermission = {
        module_name: moduleName,
        [key]: true,
      };
      updatedPermissions.push(newModulePermission);
    }

    menuSubmenuObj.subMenu.forEach((submenu) => {
      if (!userPermissionData.some((item) => item.module_name === submenu.name)) {
        const newSubmenuPermission = {
          module_name: submenu.name,
          [key]: true,
        };
        updatedPermissions.push(newSubmenuPermission);
      }
    });

    setUserPermissionData(updatedPermissions);
  };
  const handleSubMenuChange = (permissionKey, subMenuName, mainMenuName) => {
    const updatedPermissions = userPermissionData.map((permission) =>
      permission.module_name === subMenuName ? { ...permission, [permissionKey]: !permission[permissionKey] } : permission
    );

    setUserPermissionData(updatedPermissions);

    const mainMenuObj = menuSubmenuList.find((menu) => menu.name === mainMenuName);
    const mainMenuPermission = userPermissionData.find((permission) => permission.module_name === mainMenuName);

    const isSubMenuChecked = mainMenuObj.subMenu.some((submenu) => {
      const submenuPermission = updatedPermissions.find((permission) => permission.module_name === submenu.name);
      return submenuPermission && submenuPermission[permissionKey];
    });

    const updatedMainMenuPermission = {
      ...mainMenuPermission,
      [permissionKey]: isSubMenuChecked ? true : false,
    };

    setUserPermissionData((prevData) => {
      const dataWithoutMainMenu = prevData.filter((item) => item.module_name !== mainMenuName);
      return [...dataWithoutMainMenu, updatedMainMenuPermission];
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await postData(
        '/accessManagement/addPermission',
        { user_id: selectedUser.value, permissions: userPermissionData },
        {},
        userData.token
      );
      dispatch(showSnackbar({ message: `User-Level permission updated successfully`, severity: 'success' }));
    } catch (error) {
      dispatch(showSnackbar({ message: error?.response?.data?.message || error.message, severity: 'error' }));
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const removeAccess = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await postData(
        '/accessManagement/addPermission',
        { user_id: selectedUser.value, permissions: [], is_deleted: true },
        {},
        userData.token
      );
      dispatch(showSnackbar({ message: `Access permission updated successfully`, severity: 'success' }));
      fetchData();
    } catch (error) {
      dispatch(showSnackbar({ message: error?.response?.data?.message || error.message, severity: 'error' }));
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };
  return (
    <Box>
      <Box display="flex" alignItems="center" gap={2}>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="body1">User Name:</Typography>
          <Select
            value={selectedUser}
            options={userOptions}
            onChange={handleUserChange}
            placeholder="Select a user"
            styles={{ container: (base) => ({ ...base, minWidth: 400 }) }}
          />
        </Box>

        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="body1">Role:</Typography>
          <Select
            value={selectedRole}
            isDisabled={selectedUser} // Disable if no user selected
            placeholder="Select a role"
            styles={{ container: (base) => ({ ...base, minWidth: 400 }) }}
            isClearable
            isSearchable={false}
          />
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <Button
            variant="contained"
            color="error"
            disabled={!userPermissionData.some((a) => a.add || a.view || a.edit || a.delete)}
            onClick={removeAccess}
            sx={{ textTransform: 'capitalize', borderRadius: '15px', lineHeight: 1.4 }}
          >
            Reset
          </Button>
        </Box>
      </Box>
      <Box mt={5}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow sx={{ backgroundColor: '#0093D3' }}>
                <TableCell style={{ width: '48px' }} />
                <TableCell style={{ width: '200px', color: 'white', fontFamily: 'poppins-regular' }}>Permission</TableCell>
                <TableCell align="center" sx={{ color: 'white', width: '120px', fontFamily: 'poppins-regular' }}>
                  View
                </TableCell>
                <TableCell align="center" sx={{ color: 'white', width: '120px', fontFamily: 'poppins-regular' }}>
                  Add
                </TableCell>
                <TableCell align="center" sx={{ color: 'white', width: '120px', fontFamily: 'poppins-regular' }}>
                  Edit
                </TableCell>
                <TableCell align="center" sx={{ color: 'white', width: '120px', fontFamily: 'poppins-regular' }}>
                  Delete
                </TableCell>
                <TableCell align="center" sx={{ color: 'white', width: '120px', fontFamily: 'poppins-regular' }}>
                  Export
                </TableCell>
                <TableCell align="center" sx={{ color: 'white', width: '120px', fontFamily: 'poppins-regular' }}>
                  Import
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userPermissionData.length
                ? menuSubmenuList.map((row) => (
                    <Row
                      key={row.name}
                      row={row}
                      userPermissionData={userPermissionData}
                      handleMenuChange={handleMenuChange}
                      handleSubMenuChange={handleSubMenuChange}
                    />
                  ))
                : ''}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        width={{ xs: '65vw', sm: '30vw' }}
        sx={{
          margin: '0px auto',
          marginTop: '30px',
          display: 'flex',
          gap: '25px',
        }}
      >
        <FilledButton text={'Save'} onClick={handleModalOpen} />
        <OutlineButton text={'Cancel'} />
      </Box>
      <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={Modalstyle}>
          <CloseRoundedIcon sx={{ float: 'right' }} onClick={() => setOpen(false)} />
          <Box width={'100%'}>
            <Typography mt={5} style={{ textAlign: 'center', fontFamily: 'poppins-regular' }}>
              Are you sure you want to make changes to permissions.
            </Typography>
            <Box
              width={'18.8vw'}
              sx={{
                margin: 'auto',
                marginTop: '35px',
                marginBottom: '10px',
                display: 'flex',
                gap: '25px',
              }}
            >
              <FilledButton text={loading ? <CircularProgress size={24} /> : 'Confirm'} disabled={loading} onClick={handleSubmit} />
              <OutlineButton text={'Cancel'} onClick={() => setOpen(false)} />
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default UserLevelPermission;
