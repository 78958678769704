// export const BOS_GST = '27AATFB6959Q1Z7';
export const BOS_GST = '33AATFB6959Q1ZE';

export const formattedPrice = (amount) => {
  if (amount == null || isNaN(amount)) {
    // throw new Error('Input must be a valid number');
    return amount;
  }

  const parts = amount.toString().split('.');
  let integerPart = parts[0];
  const decimalPart = parts[1] ? `.${parts[1]}` : '';

  const lastThree = integerPart.slice(-3);
  const otherNumbers = integerPart.slice(0, -3);
  if (otherNumbers !== '') {
    integerPart = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + ',' + lastThree;
  }

  return integerPart + decimalPart;
};

export function getPermision(permis, module_name) {
  return (
    (permis?.userAccessPermissions?.length > 0 ? permis?.userAccessPermissions : null) ??
    (permis?.accessPermissions?.length > 0 ? permis?.accessPermissions : null)
  )?.find((a) => a?.module_name === module_name);
}
